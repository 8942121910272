import Image from 'next/image'

import { Container } from '@/components/Container'
import backgroundImage from '@/images/background-color-expanded.png'

export function Hero() {
  return (
    <div className="relative min-h-[80vh] pt-10 pb-20 sm:py-24">
      <div className="absolute inset-x-0 -bottom-48 -top-48 overflow-hidden">
        <Image
          className="absolute top-0 right-0 h-[110%] w-full translate-y-[20%] overflow-x-hidden object-cover object-[91%] opacity-100 sm:translate-y-[-6%] md:object-right-top"
          src={backgroundImage}
          alt=""
          sizes="(min-width: 768px) 1440px, 100vw"
          priority
        />

        <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white" />
        <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-br from-white opacity-0 sm:opacity-80 md:opacity-80 lg:opacity-0" />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:max-w-4xl lg:px-12">
          <h1 className="font-display text-5xl tracking-tighter text-primary-900 sm:text-7xl">
            Maegan&nbsp;and&nbsp;Zev&nbsp;are getting&nbsp;married!
          </h1>
          <div className="mt-6 space-y-6 text-2xl font-bold tracking-tight text-primary-900">
            <p>And we want you to come celebrate with us!</p>
          </div>
        </div>
      </Container>
    </div>
  )
}
