import Head from 'next/head'

import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Hero } from '@/components/Hero'
import { Tldr } from '@/components/Tldr'
import { Lodging } from '@/components/Lodging'
import { Schedule } from '@/components/Schedule'
import { Timeline } from '@/components/Timeline'
import { Location } from '@/components/Location'
import { Faq } from '@/components/Faq'
import { useEffect } from 'react'
import { useLocalStorage } from '@/lib/frontend-utils'
import { useRouter } from 'next/router'

export default function Home() {
  const router = useRouter()
  const [name, setName] = useLocalStorage('name', '')
  const [email, setEmail] = useLocalStorage('email', '')

  useEffect(() => {
    if (router.isReady && router.query.pre) {
      const pre = Buffer.from(router.query.pre as string, 'base64').toString(
        'utf-8'
      )
      const params = new URLSearchParams(pre)
      if (params.get('n')) {
        setName(params.get('n') as string)
      }
      if (params.get('e')) {
        setEmail(params.get('e') as string)
      }
      if (params.has('n') || params.has('e')) {
        fetch(`/api/notif?pre=${router.query.pre}`)
      }
    }
  }, [router.isReady, router.query.pre, setName, setEmail])

  return (
    <>
      <Head>
        <title>Maegan &amp; Zev</title>
        <meta
          name="description"
          content="Wedding information and RSVP website for Maegan and Zev's wedding on June 17, 2023."
        />
      </Head>
      <Header />
      <main>
        <Hero />
        <Tldr />
        <Schedule />
        <Timeline />
        <Location />
        <Lodging />
        <Faq />
      </main>
      <Footer />
    </>
  )
}
