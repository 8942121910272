import { Container } from '@/components/Container'
import Link from 'next/link'

const content = [
  {
    Q: 'Do you have a registry?',
    A: (
      <>
        If you are interested we have created{' '}
        <Link className="underline" href="/gifts">
          a wedding registry
        </Link>
        , which includes a honeymoon cash fund. However, please know that your
        presence on our big day is the greatest gift of all, and we do not
        expect anything from our guests. Your love and support are all that we
        need to make this day perfect.
      </>
    ),
  },
  {
    Q: 'Can I bring a date?',
    A: 'Yes, but please reach out to either Zev or Maegan so that we will have accurate numbers for catering and rentals.',
  },
  {
    Q: 'What should I wear?',
    A: 'Both the ceremony and reception are outdoors, with some covered tents. We anticipate warm to hot weather for the date, but please be prepared for it to cool off in the evening. We suggest semi-formal to dressy-casual. As the setting is generally upon grass, be mindful of the footwear you choose.',
  },
  {
    Q: 'Is the wedding child (or pet) friendly?',
    A: 'Pets are welcome at the reception following the ceremony. Please let us know if you plan to bring your furry friends! Your children are welcome to both events, again, please ensure that you write a comment in your RSVP to ensure accurate numbers for catering and rentals.',
  },
  {
    Q: 'How are we getting from the ceremony to the reception?',
    A: 'While the venues are only a few minutes drive apart, we are not offering a shuttle. Guests will be responsible for making their way to the reception property following the ceremony and charcuterie. Please be aware that there will be alcohol available at the ceremony, so please coordinate with other guests to ensure that a designated driver can transport you.',
  },
  {
    Q: 'Do I have to stay the night?',
    A: 'Totally up to you! We welcome everyone to stay the night, but we understand that some guests may not be able to.',
  },
  {
    Q: 'Can I take and post pictures of the wedding on social media?',
    A: (
      <>
        Yes, absolutely. However during our ceremony, we will ask you to leave
        the photography to our talented photographer{' '}
        <Link className="underline" href="https://www.csaimoto.com/">
          Christina Saimoto
        </Link>
        . If you are posting your pictures, please use the hashtag{' '}
        <Link href="https://instagram.com/explore/tags/isertwedding">
          <b>#isertwedding</b>
        </Link>{' '}
        so that we can enjoy later.
      </>
    ),
  },
]

function QandA({
  question,
  answer,
}: {
  question: string
  answer: JSX.Element | string
}) {
  return (
    <>
      <h3 className=" pt-2 font-display text-3xl font-medium tracking-tighter text-primary-900 sm:text-3xl">
        {question}
      </h3>
      <p className="mt-4 font-display text-xl tracking-tight text-primary-900">
        {answer}
      </p>
    </>
  )
}

export function Faq(): JSX.Element {
  return (
    <section id="faq" aria-labelledby="faq-title" className="pt-8">
      <Container className="mx-auto max-w-4xl">
        <h2
          className="font-display text-4xl font-medium tracking-tighter text-primary-900 sm:text-5xl"
          id="faq-title"
        >
          Frequently asked
        </h2>
        <div>
          {content.map(({ Q, A }) => (
            <QandA key={Q} question={Q} answer={A} />
          ))}
        </div>
      </Container>
    </section>
  )
}
