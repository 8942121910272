import Link from 'next/link'
import { useState, useEffect, useRef } from 'react'
import { Button } from './Button'

export function Tldr() {
  const ref = useRef<HTMLDivElement>(null)
  const [pano, setPano] = useState<google.maps.StreetViewPanorama>()

  useEffect(() => {
    if (ref.current && !pano) {
      const winery = { lat: 50.9584808, lng: -119.3316936 }
      const pano = new google.maps.StreetViewPanorama(ref.current, {
        position: winery,
        pov: { heading: 105, pitch: 0 },
        zoomControl: false,
        linksControl: false,
        fullscreenControl: true,
        addressControl: false,
        controlSize: 1,
      })
      pano.setPano(
        'CAoSLEFGMVFpcFBoaXk0VGt1Q21uR1NDMG9hazFnaWtxY2lOSDJhZXdlY0FGWUdm'
      )
      setPano(pano)
    }
  }, [ref, pano])

  return (
    <div className="relative  py-16">
      <div
        className="absolute inset-x-0 top-0 hidden h-1/2  lg:block"
        aria-hidden="true"
      />
      <div className="mx-auto max-w-7xl bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 hidden lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:block lg:bg-transparent lg:py-16">
            <div
              className="absolute inset-x-0 h-1/2  lg:hidden"
              aria-hidden="true"
            />
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <div
                  className="h-96 rounded-3xl object-cover object-center shadow-2xl"
                  ref={ref}
                ></div>
              </div>
            </div>
          </div>

          <div className="relative bg-primary-200 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div
              className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
              aria-hidden="true"
            >
              <svg
                className="absolute bottom-full left-full translate-y-1/3 -translate-x-2/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={2}
                      y={2}
                      width={8}
                      height={8}
                      transform="rotate(-45 2 2)"
                      className="text-primary-100"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
              <svg
                className="absolute top-full -translate-y-1/3 -translate-x-1/3 transform xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={2}
                      y={2}
                      width={8}
                      height={8}
                      transform="rotate(-45 2 2)"
                      className="text-primary-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={400}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md space-y-6 py-12 px-4 sm:max-w-3xl sm:py-16 sm:px-6 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2
                className="text-3xl font-bold tracking-tight text-primary-900"
                id="join-heading"
              >
                Ceremony details
              </h2>
              <dl className="mt-10 grid grid-cols-2 gap-y-6 gap-x-10 sm:mt-16 sm:gap-y-10 sm:gap-x-16 sm:text-center lg:auto-cols-auto lg:grid-flow-col lg:grid-cols-none lg:justify-start lg:text-left">
                {[
                  ['On', 'June 17, 2023'],
                  ['Venue', 'Celista Estate Winery'],
                  ['Location', 'Scotch Creek, BC'],
                ].map(([name, value]) => (
                  <div key={name}>
                    <dt className="font-mono text-base text-primary-900">
                      {name}
                    </dt>
                    <dd className="mt-0.5 text-2xl font-semibold tracking-tight text-primary-900">
                      {value}
                    </dd>
                  </div>
                ))}
              </dl>
              <div className="mt-4">
                Ceremony to be held at Celista Estate Winery with reception to
                follow at Thane &amp; Memory&apos;s property 15 minutes away.
                Camping is encouraged and widely available on the property. See
                below for more details.
              </div>
              <Link href="/location">
                <Button className="mt-10 w-full">Getting Here</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
