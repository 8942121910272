import { ReactNode, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { Tab } from '@headlessui/react'

import { Container } from '@/components/Container'
import backgroundImage from '@/images/birks.jpg'
import { classNames } from '@/lib/frontend-utils'
import { description } from 'fastatus'

type Timeslot = {
  name: string
  description: string
  start: string
  end: string
}

type ScheduleEntry = {
  date: string
  dateTime: string
  summary: string
  timeSlots: Timeslot[]
}

const schedule: ScheduleEntry[] = [
  {
    date: 'Friday, June 16',
    dateTime: '2023-06-16',
    summary: 'Travel to Scotch Creek.',
    timeSlots: [
      {
        name: 'Arrive',
        description: "You're free to show up anytime after 1",
        start: '1:00PM',
        end: 'Onwards',
      },
      {
        name: 'Set up camp',
        description:
          "There's only 3 beds in at the property, so bring your tent, RV, trailer or AirBnB",
        start: 'Sometime after 1PM',
        end: 'However long it takes',
      },
      {
        name: 'Property tours',
        description:
          'Wander around the fields and play some yard games. Set up may still be in process, so beware, you may be asked to help.',
        start: '2:00PM',
        end: '3:00PM',
      },
      {
        name: 'Swim in the lake',
        description:
          'The Shushwap Lake Provincial Park is only a few minutes away.',
        start: '3:00PM',
        end: '4:00PM',
      },
      {
        name: 'Pig roast dinner',
        description:
          "We're roasting a pig! Come eat it with us (Vegetarian chili and coleslaw too)",
        start: '5:00PM',
        end: '12:00PM',
      },
    ],
  },
  {
    date: 'Saturday, June 17',
    dateTime: '2023-06-17',
    summary: 'This is the day to be here for!',
    timeSlots: [
      {
        name: 'Breakfast',
        description: 'Simple coffee, fruit and granola - continental style!',
        start: '9:00AM',
        end: '10:00AM',
      },
      {
        name: 'Get all fancied up',
        description: 'Put on your best clothes, we have a wedding to attend!',
        start: '10:00AM',
        end: '11:00AM',
      },
      {
        name: 'Head over to the vineyard',
        description:
          "It's a 15 minute drive away! Be there by 12 at the latest.",
        start: '11:30AM',
        end: '12:00PM',
      },
      {
        name: 'Wedding Ceremony',
        description: 'The important bit',
        start: '12:30PM',
        end: '1:30PM',
      },
      {
        name: 'Photos and snacks',
        description: 'Mmmm charcuterie and wine',
        start: '2:00PM',
        end: '3:30PM',
      },
      {
        name: 'Head back to the reception',
        description: "Please be safe and responsible, don't drink and drive!",
        start: '3:30PM',
        end: '4:00PM',
      },
      {
        name: 'Dinner/Drinks/Dessert',
        description: 'Tacos, beer, and cupcakes. What more could you want?',
        start: '5:00PM',
        end: '7:00PM',
      },
      {
        name: 'Dance and celebrate',
        description: 'All night long!',
        start: '7:30PM',
        end: 'Dawn',
      },
    ],
  },
  {
    date: 'Sunday, June 18',
    dateTime: '2023-06-18',
    summary: 'Brunch and bye-byes 👋',
    timeSlots: [
      {
        name: 'Brunch',
        description: 'Hashbrowns, coffee, and granola',
        start: '10:00AM',
        end: '11:00AM',
      },
      {
        name: 'Pack up and head home',
        description: 'We demand hugs before you leave.',
        start: '11:00PM',
        end: '12:00PM',
      },
      {
        name: 'Travel',
        description: '4.5h to Vancouver or 1.5h to Revelstoke',
        start: '12:00PM',
        end: '1:00PM',
      },
    ],
  },
]

function ScheduleStatic() {
  return (
    <div className="hidden lg:grid lg:grid-cols-3 lg:gap-x-8">
      {schedule.map((day) => (
        <section key={day.dateTime}>
          <DaySummary day={day} />
          <TimeSlots day={day} className="mt-10" />
        </section>
      ))}
    </div>
  )
}

function ScheduleTabbed() {
  const [tabOrientation, setTabOrientation] = useState('horizontal')
  const [selectedIndex, setSelectedIndex] = useState(1)
  const tabs = useRef<(HTMLDivElement | null)[]>(
    Array(schedule.length).fill(null)
  )

  useEffect(() => {
    const smMediaQuery = window.matchMedia('(min-width: 640px)')

    function onMediaQueryChange(matches: boolean) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(smMediaQuery.matches)
    smMediaQuery.addEventListener('change', (ev) =>
      onMediaQueryChange(ev.matches)
    )

    const center = tabs.current.at(Math.floor(tabs.current.length / 2))
    if (center && center.parentElement) {
      center.parentElement.scrollLeft = center.offsetLeft
    }

    return () => {
      smMediaQuery.removeEventListener('change', (ev) =>
        onMediaQueryChange(ev.matches)
      )
    }
  }, [])

  return (
    <Tab.Group
      vertical={tabOrientation === 'vertical'}
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-6 sm:grid-cols-2 lg:hidden">
        <Tab.List
          className={classNames(
            '-mx-4 flex gap-x-4 gap-y-10 overflow-x-auto pl-4 pb-4 sm:mx-0 sm:flex-col sm:pb-0 sm:pl-0 sm:pr-8',
            tabOrientation == 'horizontal' && 'snap-x snap-proximity'
          )}
        >
          {schedule.map((day, dayIndex) => (
            <div
              ref={(el) => (tabs.current[dayIndex] = el)}
              key={day.dateTime}
              className={classNames(
                'relative w-3/4 flex-none pr-4 sm:w-auto sm:pr-0',
                dayIndex !== selectedIndex && 'opacity-70',
                dayIndex === 0
                  ? 'snap-end'
                  : dayIndex === schedule.length - 1
                  ? 'snap-start'
                  : 'snap-center'
              )}
            >
              <DaySummary
                day={{
                  ...day,
                  date: (
                    <Tab className="[&:not(:focus-visible)]:focus:outline-none">
                      <span className="absolute inset-0" />
                      {day.date}
                    </Tab>
                  ),
                }}
              />
            </div>
          ))}
        </Tab.List>
        <Tab.Panels>
          {schedule.map((day) => (
            <Tab.Panel
              key={day.dateTime}
              className="[&:not(:focus-visible)]:focus:outline-none"
            >
              <TimeSlots day={day} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </div>
    </Tab.Group>
  )
}

function DaySummary({
  day,
}: {
  day: Omit<ScheduleEntry, 'date'> & { date: any }
}) {
  return (
    <>
      <h3 className="text-2xl font-semibold tracking-tight text-primary-900">
        <time dateTime={day.dateTime}>{day.date}</time>
      </h3>
      <p className="mt-1.5 text-base tracking-tight text-primary-900">
        {day.summary}
      </p>
    </>
  )
}

function TimeSlots({
  day,
  className,
}: {
  day: ScheduleEntry
  className?: string
}) {
  return (
    <ol
      role="list"
      className={classNames(
        className,
        'space-y-8 bg-white/60 py-14 px-10 text-center shadow-xl shadow-primary-900/5 backdrop-blur'
      )}
    >
      {day.timeSlots.map((timeSlot, timeSlotIndex) => (
        <li
          key={timeSlot.start}
          aria-label={`${timeSlot.name} talking about ${timeSlot.description} at ${timeSlot.start} - ${timeSlot.end}`}
        >
          {timeSlotIndex > 0 && (
            <div className="mx-auto mb-8 h-px w-48 bg-indigo-500/10" />
          )}
          <h4 className="text-lg font-semibold tracking-tight text-primary-900">
            {timeSlot.name}
          </h4>
          {timeSlot.description && (
            <p className="mt-1 tracking-tight text-primary-900">
              {timeSlot.description}
            </p>
          )}
          <p className="mt-1 font-mono text-sm text-primary-500">
            <time dateTime={`${day.dateTime}T${timeSlot.start}-08:00`}>
              {timeSlot.start}
            </time>{' '}
            -{' '}
            <time dateTime={`${day.dateTime}T${timeSlot.end}-08:00`}>
              {timeSlot.end}
            </time>{' '}
          </p>
        </li>
      ))}
    </ol>
  )
}

export function Schedule() {
  return (
    <section
      id="schedule"
      aria-labelledby="schedule-title"
      className="py-20 sm:py-32"
    >
      <Container className="relative z-10">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-4xl lg:pr-24">
          <h2
            className="font-display text-4xl font-medium tracking-tighter text-primary-900 sm:text-5xl"
            id="schedule-title"
          >
            Weekend Schedule
          </h2>
          <p className="mt-4 font-display text-2xl tracking-tight text-primary-900">
            Can&apos;t wait to see you! Here is what we will be up to this weekend.
          </p>
        </div>
      </Container>
      <div className="relative mt-14 sm:mt-24">
        <div className="sticky inset-x-0 -top-40 -bottom-32 h-screen overflow-hidden bg-indigo-50">
          <Image
            className="absolute h-full object-cover opacity-90"
            src={backgroundImage}
            alt=""
            sizes="100vw"
          />
          <div className="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-white" />
          <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white" />
        </div>
        <Container className="relative -top-[100vh]">
          <ScheduleTabbed />
          <ScheduleStatic />
        </Container>
      </div>
    </section>
  )
}
