import Image, { StaticImageData } from 'next/image'

import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { VscGlobe as WebIcon } from 'react-icons/vsc'
import { FiPhoneOutgoing as PhoneIcon } from 'react-icons/fi'

import AvatarImage1 from '@/images/accommodation-avatars/Cottage-20-Exterior-338.jpg'
import AvatarImage2 from '@/images/accommodation-avatars/eaa0bb36-e0e5-44cf-9941-e209f429124e.jpg'
import AvatarImage3 from '@/images/accommodation-avatars/ffafa58f.f10.jpg'
import AvatarImage4 from '@/images/accommodation-avatars/WLC_thimbleberry_exterior_01.webp'
import AvatarImage5 from '@/images/accommodation-avatars/6e5e1c3620e7c33d65d2783627f5348e.png'
import AvatarImage6 from '@/images/accommodation-avatars/42.jpg'

const lodging: {
  name: string
  href: string
  imageUrl: StaticImageData
  description: string
  telephone?: string
}[] = [
  {
    name: 'Scotch Creek Cottages',
    href: 'https://www.shuswap.ca/shuswap-cabin-rental-prices/june-shoulder-season',
    imageUrl: AvatarImage1,
    description:
      'Lakeside cabins only 15 minutes away. Good for groups of 4-6 people.',
  },
  {
    name: 'The Hideout at Crowfoot Mountain',
    href: 'https://www.thehideoutatcrowfoot.com/en/stays?Arrival=2023-06-16&Departure=2023-06-18',
    imageUrl: AvatarImage2,
    description:
      "Really close, and lots of beds if you're willing to group up with other guests.",
  },
  {
    name: 'Bayside Estate A-frame Cabin',
    href: 'https://www.vrbo.com/9615312ha?noDates=true&unitId=8697329',
    imageUrl: AvatarImage3,
    description:
      'Also close, but minimum 7 night stay - might be able to book a shorter stay if you ask nicely.',
  },
  {
    name: 'Woodland Cabins',
    href: 'https://www.woodlandcabins.ca/home',
    imageUrl: AvatarImage4,
    description: 'Beautiful micro cabins on White Lake. 50 minutes away.',
  },
  {
    name: 'White Sands Cottages',
    href: 'http://www.whitesandscottages.com/cottage-amenities.html',
    imageUrl: AvatarImage5,
    description: 'Group cabins across the lake in Sorrento. 35 minutes away.',
  },
  {
    name: 'Overlander Motel',
    href: 'https://www.overlandermotel.com/',
    imageUrl: AvatarImage6,
    description: "It's a motel. 50 minutes away.",
  },
]

export function Lodging() {
  return (
    <section id="lodging" aria-label="Lodging">
      <Container>
        <div className="relative -mx-4 overflow-hidden bg-primary-200 bg-topography py-20 px-4 sm:-mx-6 sm:px-6 md:mx-0 md:rounded-5xl md:px-16 xl:px-24 xl:py-36">
          <div className="relative mx-auto max-w-2xl  xl:max-w-none">
            <div>
              <p className="font-display text-4xl font-medium tracking-tighter text-primary-900 sm:text-5xl">
                Not interested in camping?
              </p>
              <p className="mt-4 text-lg tracking-tight text-primary-900">
                Here are a few links for websites that have cabins and hotels in
                the area.{' '}
                <b>
                  Consider booking as early as possible because there are
                  limited options in the area.
                </b>
              </p>
            </div>
          </div>

          <ul
            role="list"
            className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            {lodging.map((accommodation, index) => {
              return (
                <li
                  key={accommodation.href}
                  className="col-span-1 divide-y divide-secondary-200 rounded-lg bg-white shadow"
                >
                  <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-primary-900">
                          {accommodation.name}
                        </h3>
                      </div>
                      <p className="mt-1 w-full whitespace-normal text-sm text-primary-500">
                        {accommodation.description}
                      </p>
                    </div>
                    <Image
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-secondary-300 object-cover"
                      src={accommodation.imageUrl}
                      alt=""
                      height={40}
                      sizes="40px"
                    />
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-secondary-200">
                      <div className="flex w-0 flex-1">
                        <a
                          href={accommodation.href}
                          className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-primary-700 hover:text-primary-500"
                        >
                          <WebIcon
                            className="h-5 w-5 text-secondary-400"
                            aria-hidden="true"
                          />
                          <span className="ml-3">Website</span>
                        </a>
                      </div>
                      {accommodation.telephone && (
                        <div className="-ml-px flex w-0 flex-1">
                          <a
                            href={`tel:${accommodation.telephone}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-primary-700 hover:text-primary-500"
                          >
                            <PhoneIcon
                              className="h-5 w-5 text-secondary-400"
                              aria-hidden="true"
                            />
                            <span className="ml-3">Call</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </Container>
    </section>
  )
}
