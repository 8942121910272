import { useEffect, useId, useState } from 'react'
import Image from 'next/image'
import { Tab } from '@headlessui/react'

import { Container } from '@/components/Container'
import { DiamondIcon } from '@/components/DiamondIcon'

import HelloImage from '@/images/timeline/us-hells-gate.jpg'
import Hi5Image from '@/images/timeline/us-mt-revelstoke.jpg'
import DatingImage from '@/images/timeline/us-sooke-hike.jpg'
import EngagedImage from '@/images/timeline/us-love-lake.jpg'
import MarriedImage from '@/images/timeline/us-mexico.jpg'
import { classNames } from '@/lib/frontend-utils'

const days = [
  {
    name: 'Hello',
    date: 'April 4, 2015',
    dateTime: '2015-04-04',
    title: 'The day we met',
    text: "Was on Maegan's parent's property on Saltspring Island. Zev was there camping with Nick, and we swung by to say hi.",
    image: HelloImage,
    classNames: '',
  },
  {
    name: 'Highfive',
    date: 'Feb 5, 2016',
    dateTime: '2016-02-05',
    title: 'The day we highfived',
    text: "Maegan told Zev she liked him, and he highfived her. Then without missing a beat, he suggested that they go play Lego Marvel's Superheroes on the Xbox.",
    image: Hi5Image,
    classNames: '',
  },
  {
    name: 'Dating',
    date: 'April 26, 2016',
    dateTime: '2016-04-26',
    title: '"Unofficially"',
    text: "We actually started dating a few days earlier, but Zev argued that it would be easier for him to remember their dating-anniversary by shifting it to be exactly one month before his birthday. (That didn't help him remember..)",
    image: DatingImage,
    classNames: '',
  },
  {
    name: 'Engaged',
    date: 'July 25, 2021',
    dateTime: '2021-07-25',
    title: 'In the middle of no where',
    text: 'Zev proposed to Maegan at Love Lake, after a 2 day kayak and hike deep into Strathcona Park on central Vancouver Island.',
    image: EngagedImage,
    classNames: '',
  },
  {
    name: 'Married',
    date: 'June 17, 2023',
    dateTime: '2023-06-17',
    title: 'So begins the rest of our lives',
    text: "That's what you're invited to celebrate with us!",
    image: MarriedImage,
    classNames: '',
  },
]

export function Timeline() {
  let id = useId()
  let [tabOrientation, setTabOrientation] = useState('horizontal')
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange(matches: boolean) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery.matches)
    lgMediaQuery.addEventListener('change', (ev) =>
      onMediaQueryChange(ev.matches)
    )

    return () => {
      lgMediaQuery.removeEventListener('change', (ev) =>
        onMediaQueryChange(ev.matches)
      )
    }
  }, [])

  return (
    <section
      id="timeline"
      aria-labelledby="timeline-title"
      className="py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="timeline-title"
            className="font-display text-4xl font-medium tracking-tighter text-primary-600 sm:text-5xl"
          >
            Our Story
          </h2>
        </div>
        <Tab.Group
          vertical={tabOrientation === 'vertical'}
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <div className="mt-14 grid grid-cols-1 items-start gap-y-8 gap-x-8 sm:mt-16 sm:gap-y-16 lg:mt-24 lg:grid-cols-4">
            <div className="relative -mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:block sm:overflow-visible sm:pb-0">
              <div className="absolute bottom-0 top-2 left-0.5 hidden w-px bg-primary-200 lg:block" />
              <Tab.List className="grid auto-cols-auto grid-flow-col justify-start gap-x-8 gap-y-10 whitespace-nowrap px-4 sm:mx-auto sm:max-w-2xl sm:grid-cols-3 sm:px-0 sm:text-center lg:grid-flow-row lg:grid-cols-1 lg:text-left">
                {days.map((day, dayIndex) => (
                  <div key={day.dateTime} className="relative lg:pl-8">
                    <DiamondIcon
                      className={classNames(
                        'absolute top-[0.5625rem] left-[-0.5px] hidden h-1.5 w-1.5 overflow-visible lg:block',
                        dayIndex === selectedIndex
                          ? 'fill-primary-600 stroke-primary-600'
                          : 'fill-transparent stroke-secondary-300'
                      )}
                    />
                    <div className="relative">
                      <div
                        className={classNames(
                          'text-sm font-normal',
                          dayIndex === selectedIndex
                            ? 'text-primary-900'
                            : 'text-secondary-400'
                        )}
                      >
                        <Tab className="text-left [&:not(:focus-visible)]:focus:outline-none">
                          <time dateTime={day.dateTime} className="inset-0">
                            {day.date}
                          </time>
                          <span
                            className={classNames(
                              'mt-1.5 block text-2xl font-semibold tracking-tight',
                              dayIndex === selectedIndex
                                ? 'text-primary-900'
                                : 'text-secondary-400'
                            )}
                          >
                            {day.name}
                          </span>
                        </Tab>
                      </div>
                    </div>
                  </div>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className="lg:col-span-3">
              {days.map((day, dayIndex) => (
                <Tab.Panel
                  key={day.dateTime}
                  className="[&:not(:focus-visible)]:focus:outline-none"
                  unmount={false}
                >
                  <div>
                    <div className="group relative h-[60vh] transform overflow-hidden rounded-4xl">
                      <div className="absolute inset-0 bg-primary-50">
                        <Image
                          className={classNames(
                            'absolute inset-0 h-full w-full object-cover',
                            day.classNames
                          )}
                          src={day.image}
                          alt=""
                          priority
                          sizes="(min-width: 1280px) 35vw, (min-width: 1024px) 70vw, (min-width: 768px) 100vw, (min-width: 640px) 50vw, 100vw"
                        />
                      </div>
                    </div>
                    <h3 className="mt-8 font-display text-xl font-bold tracking-tight text-primary-900">
                      {day.title}
                    </h3>
                    <p className="mt-1 text-base tracking-tight text-primary-500">
                      {day.text}
                    </p>
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </Container>
    </section>
  )
}
